<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1157_39113" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1157_39113)">
      <path d="M12 17.7889C6.57606 17.7889 2.29526 12.4542 2.11537 12.2271C1.96154 12.0321 1.96154 11.7573 2.11537 11.5621C2.29528 11.3349 6.57606 6 12 6C17.4239 6 21.7047 11.3346 21.8846 11.5617C22.0385 11.7568 22.0385 12.0315 21.8846 12.2267C21.7047 12.454 17.4239 17.7889 12 17.7889ZM3.23937 11.8942C4.25037 13.0344 7.837 16.7168 12 16.7168C16.1705 16.7168 19.751 13.0356 20.7606 11.8946C19.7496 10.7543 16.163 7.07199 12 7.07199C7.82951 7.07199 4.24897 10.7531 3.23937 11.8942Z" fill="#BBBBBB"/>
      <path d="M11.9963 15.2882C10.1251 15.2882 8.60254 13.7657 8.60254 11.8945C8.60254 10.0233 10.1251 8.50073 11.9963 8.50073C13.8675 8.50073 15.39 10.0233 15.39 11.8945C15.39 13.7657 13.8678 15.2882 11.9963 15.2882ZM11.9963 9.57251C10.716 9.57251 9.67419 10.6143 9.67419 11.8946C9.67419 13.1749 10.7159 14.2167 11.9963 14.2167C13.2765 14.2167 14.3183 13.1749 14.3183 11.8946C14.3183 10.6143 13.2768 9.57251 11.9963 9.57251Z" fill="#BBBBBB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPasswordShow"
}
</script>